setTimeout(initStickyFooter, 1500)

function initStickyFooter() {
    const protocol = window.location.protocol
    const host = window.location.host
    const pathSegments = window.location.pathname.split("/").filter(Boolean)
    const language = pathSegments[0]
    const url = `${protocol}//${host}/${language}`
    const stickyFooterClose = getCookie("sticky_footer_close")

    if (!stickyFooterClose) {
        const stickyFooterData = localStorage.getItem("sticky-footer")
        if (stickyFooterData) {
            const {time: storedTime, loggedIn: isLoggedIn} = JSON.parse(stickyFooterData)
            const authStatus = !!document.querySelector("body.logged-in")
            if (Date.now() > Date.parse(storedTime) + 24 * 60 * 60 * 1000 || isLoggedIn !== authStatus) {
                getStickyFooterFunc()
            } else {
                setTimeout(renderStickyFooter, 20000)
            }
        } else {
            getStickyFooterFunc()
        }
    }

    async function getStickyFooterFunc() {
        try {
            const response = await fetch(`${url}/get-footer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content
                }
            })

            if (!response.ok) {
                throw new Error("Network response was not ok")
            }

            const data = await response.json()
            if (data) {
                localStorage.setItem("sticky-footer", JSON.stringify(data))
                setTimeout(renderStickyFooter, 20000)
            } else {
                localStorage.removeItem("sticky-footer")
            }
        } catch (error) {
            console.error("Error fetching data:", error)
        }
    }

    function renderStickyFooter() {
        const stickyFooterData = localStorage.getItem("sticky-footer")
        if (stickyFooterData) {
            const footer = JSON.parse(stickyFooterData).footer
            const link = JSON.parse(stickyFooterData).link
            const stickyFooterDiv = document.createElement("div")
            stickyFooterDiv.className = "sticky-footer"
            stickyFooterDiv.innerHTML = `
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-7 col-lg-8 col-xl-9">
                            <p>${footer.locale.text}</p>
                        </div>
                        <div class="col-12 col-md-5 col-lg-4 col-xl-3">
                            <div class="sticky-footer-button-block">
                                <a href="${link}" data-id="${footer.id}" data-lifetime="${footer.cookie_lifetime}" id="acceptStickyFooter" class="button button-primary button-high">
                                    ${footer.locale.button_text}
                                </a>
                                <img src="/images/svg/close-cross.svg" class="close" id="closeStickyFooter" alt="Close" />
                            </div>
                        </div>
                    </div>
                </div>`
            document.body.appendChild(stickyFooterDiv)

            const stickyFooter = document.querySelector(".sticky-footer")
            if (stickyFooter) {
                setTimeout(() => {
                    stickyFooter.classList.add("visibleStickyFooter")
                }, 10000)
            }

            setupFooterEventHandlers(footer.id, footer.cookie_lifetime, link)
        }
    }

    function setupFooterEventHandlers(id, lifetime, href) {
        const acceptStickyFooter = document.getElementById("acceptStickyFooter")
        const closeStickyFooter = document.getElementById("closeStickyFooter")

        if (acceptStickyFooter && closeStickyFooter) {
            acceptStickyFooter.addEventListener("click", (event) => {
                event.preventDefault()
                acceptStickyFooterFunc(href, id, lifetime)
            })

            closeStickyFooter.addEventListener("click", (event) => {
                event.preventDefault()
                closeStickyFooterFunc(id, lifetime)
            })
        }
    }

    function acceptStickyFooterFunc(href, id, lifeTime) {
        fetch(`${url}/increment-footer?id=${id}&type=accept`).then(() => {
            setFooterCookies(id, lifeTime)
            const stickyFooter = document.querySelector(".sticky-footer")
            if (stickyFooter) {
                stickyFooter.classList.remove("visibleStickyFooter")
                setTimeout(() => {
                    stickyFooter.remove()
                    window.location.replace(href)
                }, 1100)
            }
        })
    }

    function closeStickyFooterFunc(id, lifeTime) {
        fetch(`${url}/increment-footer?id=${id}&type=close`)
        setFooterCookies(id, lifeTime)
        const stickyFooter = document.querySelector(".sticky-footer")
        if (stickyFooter) {
            stickyFooter.classList.remove("visibleStickyFooter")
            setTimeout(() => {
                stickyFooter.remove()
            }, 1100)
        }
    }

    function setFooterCookies(id, lifeTime) {
        const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString()
        document.cookie = `sticky_footer_close=1; expires=${expires}; path=/`
        const futureDate = new Date(Date.now() + lifeTime * 24 * 60 * 60 * 1000).toUTCString()
        document.cookie = `sticky_footer_close_${id}=1; expires=${futureDate}; path=/`
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        return parts.length === 2 ? parts.pop().split(";").shift() : null
    }
}
